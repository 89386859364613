import axios from "axios";
import { httpClient } from "./httpClient";

const API_URL = process.env.REACT_APP_API_URL + "auth/";

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        console.log("SET accessToken", JSON.stringify(response.data));
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const changePassword = (formData) => {
  return httpClient.post(`/change-password`, formData);
};

const resetPassword = (formData) => {
  return httpClient.put(`/reset-password`, formData);
};

export default {
  login,
  logout,
  changePassword,
  resetPassword
};
