import ExcelJS from 'exceljs';

export const TrainingDepartmentXlsx = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Department Training Data');

    const headers = [
        "ชื่อหน่วยงาน",
        "ระดับหน่วยงาน",
        "Cost Center",
        "จำนวนพนักงาน",
        "ชั่วโมงเรียนในห้อง",
        "ชั่วโมงเรียนออนไลน์",
        "ชั่วโมง OJT",
        "รวมชั่วโมงเรียน",
        "ชั่วโมงเรียนเฉพาะหลักสูตรที่ส่งราชการ",
        "ชั่วโมงเรียนในห้องทั้งปี",
        "ชั่วโมงเรียนออนไลน์ทั้งปี",
        "ชั่วโมง OJT ทั้งปี",
        "รวมชั่วโมงเรียนทั้งปี",
        "ชั่วโมงเรียนเฉพาะหลักสูตรที่ส่งราชการทั้งปี",
        "ค่าใช้จ่ายอบรมภายใน",
        "ค่าใช้จ่ายอบรมภายนอก",
        "รวมค่าใช้จ่ายอบรม",
        "ค่าใช้จ่ายอบรมภายในทั้งปั",
        "ค่าใช้จ่ายอบรมภายนอกทั้งปี",
        "รวมค่าใช้จ่ายอบรมทั้งปี",
    ];

    const headerRow = worksheet.addRow(headers);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    data.forEach((item) => {
        const row = [
            item["departmentName"],
            item["departmentLevel"],
            item["costCenter"],
            item["employeeCount"],
            item["classroomHours"],
            item["onlineHours"],
            item["ojtHours"],
            item["totalLearningHours"],
            item["governmentCourseHours"],
            item["yearlyClassroomHours"],
            item["yearlyOnlineHours"],
            item["yearlyOjtHours"],
            item["yearlyTotalLearningHours"],
            item["yearlyGovernmentCourseHours"],
            item["internalTrainingCost"],
            item["externalTrainingCost"],
            item["totalTrainingCost"],
            item["yearlyInternalTrainingCost"],
            item["yearlyExternalTrainingCost"],
            item["yearlyTotalTrainingCost"],
        ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    worksheet.columns = headers.map(header => ({
        header,
        width: header.length + 10,
    }));

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Individual-by-Department.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};