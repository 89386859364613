import {
  EMPLOYEEDATA_FETCHING,
  EMPLOYEEDATA_SUCCESS,
  EMPLOYEEDATA_FAILED,
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEEDATA_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case EMPLOYEEDATA_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case EMPLOYEEDATA_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
