import React, { useState } from "react";
import { Avatar, Box, Card, Container, styled, Typography, Grid } from "@mui/material";
import MyDocument from "../../../assets/-_Branding & Communication.png"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { IndividualTrainingHours } from "./IndividualTrainingHours";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    "& .MuiTypography-root": {
        fontFamily: "Kanit, sans-serif",
    },
    "& .MuiContainer-root": {
        paddingBottom: 16,
    },
});

const StyledCard = styled(Card)({
    height: "100%",
    position: "relative",
    boxShadow: "none",
    borderRadius: 16,
    border: "1px solid #919eab3d",
    border: "none",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition:
        "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
        transform: "translateY(-5px)",
    },
    "&.disabled": {
        filter: "grayscale(100%)",
        pointerEvents: "none",
        cursor: "default",
        "&:hover": {
            cursor: "default",
            transform: "translateY(0px)",
        },
    },
    "& .inner": {
        userSelect: "none",
        flexGrow: 1,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        "& div": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
        },
        "& .MuiTypography-subtitle": {
            fontSize: 20,
            lineHeight: 1.2,
            textAlign: "center",
        },
        "& .MuiTypography-caption": {
            lineHeight: 1.2,
            fontSize: 14,
        },
        "& .MuiAvatar-root": {
            pointerEvents: "none",
            width: 100,
            height: 100,
            marginBottom: 16,
        },
    },
    "& .premium": {
        position: "absolute",
        right: 20,
    },
});

export default function AllReportCenter() {
    const { t } = useTranslation();
    const history = useHistory();
    const [view, setView] = useState();
    const [open, setOpen] = useState();

    const { result: userProfile } = useSelector((state) => state.userProfile);

    const handleClose = () => {
        setOpen(false);
        setView(null);
    };

    const MenuList = [
        {
            id: 1,
            label: "ชั่วโมงอบรมรายคน",
            src: MyDocument,
            number: "R001",
            path: "/individual-training-hours"
            // disabled: true
        },
        {
            id: 2,
            label: `อบรมภายนอกรายคน`,
            src: MyDocument,
            number: "R002",
            path: "/individual-external-training"
        },
        {
            id: 3,
            label: `ชั่วโมงอบรมรายหน่วยงาน`,
            src: MyDocument,
            number: "R003",
            path: "/training-hours-department"
        },
        {
            id: 4,
            label: `Mandatory_IDP รายคน`,
            src: MyDocument,
            number: "R004",
            path: "/individual-mandatory-IDP"
        },
        {
            id: 5,
            label: `Mandatory_IDP รายหน่วยงาน`,
            src: MyDocument,
            number: "R005",
            path: "/department-mandatory-IDP"
        }
    ];

    const handleClickMenu = (menu) => {
        if (!menu.id) return;

        if (menu.path) {
            history.push(menu.path);
            return;
        } else {
            setView(menu.id);
            setOpen(true);
        };
    };

    return (
        <StyledRoot className={`page`}>
            <Container maxWidth="lg">
                <Box marginBottom="24px">
                    <Typography variant="h4">{t("Report")}</Typography>
                </Box>

                <Grid container spacing={4}>
                    <Grid item xs={12} container spacing={2}>
                        {MenuList.map((item) => (
                            <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                                <StyledCard
                                    className={item.disabled ? "disabled" : undefined}
                                    onClick={() => {
                                        handleClickMenu(item);
                                    }}
                                >
                                    <Box className="inner">
                                        <Avatar variant="rounded" src={item.src} />
                                        <Box>
                                            <Typography variant="subtitle" gutterBottom>
                                                {item.label}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle" gutterBottom>
                                                {item.number}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </StyledCard>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Container>

            {view === 1 && <IndividualTrainingHours open={open} handleClose={handleClose} />}
        </StyledRoot>
    );
};