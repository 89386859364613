import React from "react";
import ListMenu from "../../../shared/listMenu";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DvrIcon from '@mui/icons-material/Dvr';
import WorkIcon from "@mui/icons-material/Work";
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import { RequestPage } from "@mui/icons-material";

const AssessorMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <DoneAllIcon />,
      listItemText: "รายการรออนุมัติ",
      listLink: "/Assessor/AppointTest",
      listKey: "AppointTest",
    },
    {
      listItemIcon: <DoneAllIcon />,
      listItemText: "ทักษะที่ประเมิน",
      listLink: "/Assessor/MySkillList",
      listKey: "MySkillList",
    },
    {
      listItemIcon: <EventNoteRoundedIcon />,
      listItemText: "สถานะรายการ",
      listLink: "/Assessor/Status/StatusAssessor",
      listKey: "approvePerson",
    }
    // {
    //   listItemIcon: <HistoryRoundedIcon />,
    //   listItemText: "ประวัติการอบรมของทีม",
    //   listLink: "/manager/EmplearnHistory",
    //   listKey: "team-Learn-History",
    // },
    // {
    //   listItemIcon: <Groups2RoundedIcon />,
    //   listItemText: "แผนพัฒนาบุคคล",
    //   listLink: "",
    //   listKey: "headIDP",
    //   collapse: [
    //     {
    //       listItemIcon: <QuestionAnswerRoundedIcon />,
    //       listItemText: "แผนพัฒนารายบุคลทีม",
    //       listLink: "/all-idp",
    //       listKey: "all-idp",
    //     },
    //     {
    //       // listItemIcon: <ContentPasteSearchRoundedIcon />,
    //       listItemIcon: <DoneAllIcon />,
    //       listItemText: "รออนุมัติแผนพัฒนา",
    //       listLink: "/idp-approval",
    //       listKey: "idp-approval",
    //     },
    //   ],
    // },
  ];

  return <ListMenu dataListMenu={dataListMenu} menuRole={"Assessor"} />;
};

export default AssessorMenu;
