import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Box, Breadcrumbs, Container, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ButtonBlue from "../../shared/ButtonBlue";
import { Link } from "react-router-dom";
import AlertResponse from "../../shared/AlertResponse";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CardStyle from "../../Authentication/components/LoginCard";
import { getUserDepartment, getUserDivision, getUserPosition, getUserSection } from "../../../utils/userData";
import { useTranslation } from "react-i18next";
import { IndividualMandatoryXlsx } from "./xlsx-export/IndividualMandatoryXlsx";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
    "& .search-filter-container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .filter-wrap": {},
        "& .action-container": {},
    },
});

const StyledBox = styled(Box)({
    "&.open": {
        paddingLeft: 16,
        width: "calc(100% - 1000px)",
    },
    "&.close": {
        paddingLeft: 0,
        width: "100%",
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    whiteSpace: "nowrap",
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "20px 0px 20px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    padding: 8,
    whiteSpace: "nowrap",
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 0,
    },
    "&:last-of-type": {
        paddingRight: 24,
    },
}));

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

export const IndividualMandatoryPage = () => {
    const { t } = useTranslation();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [trainingData, setTrainingData] = useState(null);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    // const onChangeCompany = (newValue) => {
    //     setSelectedCompany(newValue);
    // };

    const data = [
        {
            "employeeID": "E001",
            "firstname_TH": "Somchai",
            "lastname_TH": "Suksawat",
            "personalID": "1103701234567",
            "divisionName": "IT",
            "sectionName": "Development",
            "departmentName": "Software Engineering",
            "positionName": "Software Developer",
            "mandatoryRequiredCourses": 5,
            "mandatoryCompletedCourses": 4,
            "mandatoryPendingCourses": 1,
            "mandatoryCompletionPercentage": 80,
            "mandatoryPendingPercentage": 20,
            "idpRequiredCourses": 3,
            "idpCompletedCourses": 2,
            "idpPendingCourses": 1,
            "idpCompletionPercentage": 66.67,
            "idpPendingPercentage": 33.33
        },
        {
            "employeeID": "E002",
            "firstname_TH": "Nipa",
            "lastname_TH": "Kritsana",
            "personalID": "1103709876543",
            "divisionName": "HR",
            "sectionName": "Recruitment",
            "departmentName": "Talent Acquisition",
            "positionName": "Recruiter",
            "mandatoryRequiredCourses": 6,
            "mandatoryCompletedCourses": 6,
            "mandatoryPendingCourses": 0,
            "mandatoryCompletionPercentage": 100,
            "mandatoryPendingPercentage": 0,
            "idpRequiredCourses": 4,
            "idpCompletedCourses": 4,
            "idpPendingCourses": 0,
            "idpCompletionPercentage": 100,
            "idpPendingPercentage": 0
        },
        {
            "employeeID": "E003",
            "firstname_TH": "Anan",
            "lastname_TH": "Chaiprasit",
            "personalID": "1103701122334",
            "divisionName": "Finance",
            "sectionName": "Accounting",
            "departmentName": "Financial Reporting",
            "positionName": "Accountant",
            "mandatoryRequiredCourses": 4,
            "mandatoryCompletedCourses": 3,
            "mandatoryPendingCourses": 1,
            "mandatoryCompletionPercentage": 75,
            "mandatoryPendingPercentage": 25,
            "idpRequiredCourses": 2,
            "idpCompletedCourses": 1,
            "idpPendingCourses": 1,
            "idpCompletionPercentage": 50,
            "idpPendingPercentage": 50
        }
    ];

    const columns = [
        { id: 1, label: t("EmployeeID"), minWidth: 150 },
        { id: 2, label: t("FirstName"), minWidth: 150 },
        { id: 3, label: t("LastName"), minWidth: 150 },
        { id: 4, label: t("CitizenID"), minWidth: 150 },
        { id: 5, label: t("Division"), minWidth: 150 },
        { id: 6, label: t('Department'), minWidth: 150 },
        { id: 7, label: t("Section"), minWidth: 150 },
        { id: 8, label: t('JobPosition'), minWidth: 150 },
        { id: 9, label: 'Mandatory ที่ต้องเรียน (หลักสูตร)', minWidth: 150 },
        { id: 10, label: 'Mandatory สำเร็จแล้ว (หลักสูตร)', minWidth: 150 },
        { id: 11, label: 'Mandatory  คงค้าง (หลักสูตร)', minWidth: 150 },
        { id: 12, label: '% Mandatory สำเร็จ', minWidth: 150 },
        { id: 13, label: '% Mandatory คงค้าง', minWidth: 150 },
        { id: 14, label: 'IDP ที่ต้องเรียน (หลักสูตร)', minWidth: 150 },
        { id: 15, label: 'IDP  สำเร็จแล้ว (หลักสูตร)', minWidth: 150 },
        { id: 16, label: 'IDP คงค้าง (หลักสูตร)', minWidth: 150 },
        { id: 17, label: '% IDP สำเร็จ', minWidth: 150 },
        { id: 18, label: '% IDP คงค้าง', minWidth: 150 },
    ];

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/Admin/report-center"}
                    >
                        {t("Report")}
                    </Link>
                    <Typography color="text.primary">ชั่วโมงอบรมรายคน</Typography>
                </Breadcrumbs>

                <Typography variant="h4" sx={{ mt: 3 }}>
                    ชั่วโมงอบรมรายคน
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
                            <ButtonBlue
                                variant={"contained"}
                                onClick={() => IndividualMandatoryXlsx(data)}
                            >
                                {t("Download")}
                            </ButtonBlue>
                        </Stack>
                    </Grid>
                </Grid>

                <CardStyle style={{ marginTop: "3vh" }}>
                    <Box style={{ padding: "24px" }}>
                        <Box style={{ display: "flex", position: "relative" }}>
                            <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledCellHeader
                                                align={"center"}
                                                className="sticky"
                                                style={{ minWidth: 240 }}
                                            >
                                                {t("FullName")}
                                            </StyledCellHeader>

                                            {columns.map((column) => (
                                                <StyledCellHeader key={column.id}>
                                                    {column.label}
                                                </StyledCellHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isFetching === true ? (
                                            <StyledRowContent>
                                                <StyledCellContent
                                                    colSpan={5}
                                                    style={{ height: 150 }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        {/* <img alt="loading" src={loading} style={{ width: 100, height: 100 }} /> */}
                                                    </Box>
                                                </StyledCellContent>
                                            </StyledRowContent>
                                        ) : (
                                            <Fragment>
                                                {data && data.length > 0 ? (
                                                    data
                                                        // .slice(
                                                        //     page * rowsPerPage,
                                                        //     page * rowsPerPage + rowsPerPage
                                                        // )
                                                        // .filter((row) => {
                                                        //     const fullname = `${row.firstname_TH + " " + row.lastname_TH
                                                        //         }`.toLowerCase();
                                                        //     return fullname.includes(
                                                        //         searchText.toLowerCase()
                                                        //     );
                                                        // })
                                                        .map((item, index) => (
                                                            <StyledRowContent hover key={index}>
                                                                <StyledCellContent
                                                                    className="sticky"
                                                                    style={{
                                                                        borderBottom:
                                                                            "1px dashed rgba(224, 224, 224, 1)",
                                                                    }}
                                                                >
                                                                    <div className="avatar">
                                                                        <Avatar alt={item["employeeID"]} src={`${item.imageProfile}`} />
                                                                        <div>
                                                                            <Typography className="fullname">
                                                                                {item["firstname_TH"] + " " + item["lastname_TH"]}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["employeeID"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["firstname_TH"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["lastname_TH"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["personalID"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserDivision(item) ? getUserDivision(item) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserDepartment(item) ? getUserDepartment(item) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserSection(item) ? getUserSection(item) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserPosition(item) ? getUserPosition(item) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["mandatoryRequiredCourses"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["mandatoryCompletedCourses"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["mandatoryPendingCourses"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["mandatoryCompletionPercentage"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["mandatoryPendingPercentage"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["idpRequiredCourses"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["idpCompletedCourses"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["idpPendingCourses"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["idpCompletionPercentage"]}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {item["idpPendingPercentage"]}
                                                                    </Typography>
                                                                </StyledCellContent>
                                                            </StyledRowContent>
                                                        ))
                                                ) : (
                                                    <StyledRowContent>
                                                        <StyledCellContent
                                                            colSpan={5}
                                                            style={{ height: 150 }}
                                                        >
                                                            <Typography align="center">
                                                                {t("NoData")}
                                                            </Typography>
                                                        </StyledCellContent>
                                                    </StyledRowContent>
                                                )}
                                            </Fragment>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </CardStyle>
            </Container>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot>
    );
};