const initialState = {
    TID: null,
}

const teacherReducer = (state = initialState, action) => {
    // Check to see if the reducer cares about this action
    if (action.type === 'TEACHER') {
      return {
        TID: action.TID,
      }
    }
    else{
      return state;
    }
}

export default teacherReducer;

