import ExcelJS from 'exceljs';

export const DepartmentMandatoryXlsx = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Department Mandotory Data');

    const headers = [
        "ชื่อหน่วยงาน",
        "ระดับหน่วยงาน",
        "Cost Center",
        "จำนวนพนักงาน",
        "Mandatory ที่ต้องเรียน (หลักสูตร)",
        "Mandatory สำเร็จแล้ว (หลักสูตร)",
        "Mandatory คงค้าง (หลักสูตร)",
        "% Mandatory สำเร็จ",
        "% Mandatory คงค้าง",
        "IDP ที่ต้องเรียน (หลักสูตร)",
        "IDP สำเร็จแล้ว (หลักสูตร)",
        "IDP คงค้าง (หลักสูตร)",
        "% IDP สำเร็จ",
        "% IDP คงค้าง",
    ];

    const headerRow = worksheet.addRow(headers);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    data.forEach((item) => {
        const row = [
            item["departmentName"],
            item["departmentLevel"],
            item["costCenter"],
            item["numberOfEmployees"],
            item["mandatoryCoursesRequired"],
            item["mandatoryCoursesCompleted"],
            item["mandatoryCoursesPending"],
            item["mandatoryCompletionPercentage"],
            item["mandatoryPendingPercentage"],
            item["idpCoursesRequired"],
            item["idpCoursesCompleted"],
            item["idpCoursesPending"],
            item["idpCompletionPercentage"],
            item["idpPendingPercentage"],
        ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    worksheet.columns = headers.map(header => ({
        header,
        width: header.length + 10,
    }));

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Mandotory-IDP-by-Department.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};