import React, { useEffect, useState } from "react";
import DrawerCustom from "../../shared/Drawer";
import { styled } from "@mui/material/styles";
import { Grid, Stack, Typography } from "@mui/material";
import ButtonBlue from "../../shared/ButtonBlue";
import { IndividualTrainingHoursXlsx } from "./xlsx-export/IndividualTrainingHoursXlsx";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

export const IndividualTrainingHours = (props) => {
    const { open, handleClose } = props;

    const handleDownloadXlsx = () => {
        IndividualTrainingHoursXlsx();
    };

    return (
        <DrawerCustom
            title={"Individual Training Hours"}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            Individual Training Hours
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
                            <ButtonBlue
                                variant={"contained"}
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </ButtonBlue>
                            <ButtonBlue
                                variant={"contained"}
                                onClick={() => handleDownloadXlsx()}
                            >
                                Download
                            </ButtonBlue>
                        </Stack>
                    </Grid>
                </Grid>
            </StyledRoot>
        </DrawerCustom>
    );
};