import { httpClient } from "./httpClient";
import dayjs from 'dayjs';

const getUserProfile = () => {
  return httpClient.get("profile");
};

const getAllUsersCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/users`);
};

const addUsers = (data) => {
  return httpClient.post("users", data) ;
};

const updateUser = (data) => {
  return httpClient.put(`employees`, data);
};

const updateManagerEmployee = (data) => {
  return httpClient.put(`employees/manager`, data);
};

const getDataWritePDF = (type, year, rangeDate) => {
  let startDate = null;
  let endDate = null;

  if(rangeDate){
    startDate=dayjs(rangeDate.startDate).format("YYYY/MM/DD");
    endDate=dayjs(rangeDate.endDate).format("YYYY/MM/DD");
  }

  return httpClient.get(
    `users/data-writePDF/${type}${year ? `/${year}` : ""}${
      rangeDate ? `?startDate=${startDate}&endDate=${endDate}` : ""
    }`
  );
};

export default {
  getUserProfile,
  getAllUsersCompany,
  addUsers,
  updateUser,
  getDataWritePDF,
  updateManagerEmployee
};
