const initialState = {
    CID: null,
    courseType: null,
}

const courseReducer = (state = initialState, action) => {
    // Check to see if the reducer cares about this action
    if (action.type === 'COURSE') {
      return {
        CID: action.CID,
        courseType: action.courseType,
      }
    }
    else{
      return state;
    }
}

export default courseReducer;

