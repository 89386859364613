import React, { useEffect, useState, useTransition } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link, withRouter, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";

import TranslateIcon from "@mui/icons-material/Translate";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import Logo from "../../Authentication/img/unihr_logo.png";
import ButtonBlue from "../../shared/ButtonBlue";

import { logout } from "../../../actions/auth";
import { getUserProfile } from "../../../actions/user";
import { Box } from "@mui/system";

import {
  AccountBalanceWallet,
  ShoppingCart,
  HistoryEdu,
  Bloodtype,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const drawerWidth = 280;
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    zIndex: 1100,

  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,

  },
  drawerClose: {
    overflowX: "hidden",
    width: 48,

  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 16,
  },
  content: {
    flexGrow: 1,
    padding: 16,
  },
  sectionDesktop: {
    display: "none",

  },
  sectionMobile: {
    display: "flex",

  },
  grow: {
    flexGrow: 1,
  },
}));

const StyledAppBar = styled(AppBar)(({ }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  width: "100%",
  ["@media (min-width: 1200px)"]: {
    width: "calc(100% - 281px)",
    "&.menuClose": {
      width: "100% !important",
    },
  },

  "& .MuiToolbar-regular": {
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    transition:
      "height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backdropFilter: "blur(6px)",
    ["@media (min-width: 900px)"]: {
      height: 76,
    },

    "& .MuiContainer-root": {
      ["@media (max-width: 768px)"]: {
        padding: 0,
      },
      display: "flex",
      alignItems: "center",
      ["@media (min-width: 1200px)"]: {
        padding: 0,
        "&.menuClose": {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      "& .headerAction": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .translate, .MuiDivider-root": {
          ["@media (max-width: 768px)"]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const StyledIconButtonMenu = styled(IconButton)(({ }) => ({
  //marginRight: 24,
}));

const StyledIconButtonTranslate = styled(IconButton)(({ }) => ({
  "&:hover": {
    transform: "scale(1.09) translateZ(0px)",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const { open, matchesBig, isLoggedIn } = props;
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cart, setCart] = useState([]);
  const dispatch = useDispatch();
  const isMenuOpen = Boolean(anchorEl);

  const [anchorEl_Trans, setAnchorEl_Trans] = React.useState(null);
  const open_Trans = Boolean(anchorEl_Trans);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(logout());
  };

  const handleClick_Trans = (event) => {
    setAnchorEl_Trans(event.currentTarget);
  };

  const handleClose_Trans = () => {
    setAnchorEl_Trans(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose_Trans();
  };

  useEffect(() => {
    const callCart = JSON.parse(localStorage.getItem("cart"));
    if (JSON.stringify(callCart) != JSON.stringify(cart)) {
      setCart(callCart);
    }
  }, [cart]);

  useEffect(() => {
    if (currentUser) {
      //Check JWT Token expiration
      const JWT = currentUser.accessToken;
      const jwtPayload = JSON.parse(window.atob(JWT.split(".")[1]));
      const jwtUnix = jwtPayload.exp * 1000;

      let todayUnix = Date.now();

      if (todayUnix > jwtUnix) {
        // if token expire force to logout
        logOut();
      }
      dispatch(getUserProfile(currentUser.id));
    }
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            backgroundColor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box style={{ width: 178, padding: "0 20px", margin: "12px 0" }}>
        <Typography
          variant="h6"
          style={{
            fontSize: 16,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {userProfile && `${userProfile.firstname_TH} ${userProfile.lastname_TH}`}
        </Typography>
        <Typography color="text.secondary" style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>
          {userProfile && userProfile.email}
        </Typography>
      </Box>
      <Divider />
      <MenuItem component={Link} to="/homepage" onClick={handleMenuClose}>
        <ListItemIcon>
          <HomeOutlinedIcon fontSize="small" />
        </ListItemIcon>
        Home
      </MenuItem>

      {/* <MenuItem onClick={handleMenuClose} component={Link} to="/cart">
        <ListItemIcon>
          <Badge badgeContent={cart && cart.length} color="error">
            <ShoppingCart />
          </Badge>
        </ListItemIcon>
        Cart
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/history">
        <ListItemIcon>
          <HistoryEdu />
        </ListItemIcon>
        History
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/bookingHistory">
        <ListItemIcon>
          <Bloodtype />
        </ListItemIcon>
        Health check booking
      </MenuItem> */}
      <MenuItem component={Link} to="/" onClick={logOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      {/* <MenuItem component={Link} to="/login" onClick={logOut}>
        Logout
      </MenuItem> */}
      {/* <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: props.open && classes.hide,
            })}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Clock
          </Typography>

          <div className={classes.grow} />

          <div>
            <IconButton
              aria-label="show 17 new notifications"
              color="inherit"
              component={NavLink}
              to="/notification"
              size="large">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon style={{ color: "#dddddd" }}/>
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              size="large">
              <Avatar alt={currentUser.username} src={`${process.env.REACT_APP_API_URL}image/profile/${currentUser.image}`} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar> */}
      <ElevationScroll {...props}>
        <StyledAppBar
          className={clsx({ menuClose: open === false && matchesBig })}
        >
          <Toolbar>
            <Container
              className={clsx({ menuClose: open === false && matchesBig })}
            >
              {isLoggedIn && userProfile &&
                <StyledIconButtonMenu
                  aria-label="open drawer"
                  onClick={props.handleDrawerOpen}
                  edge="start"
                  size="large"
                >
                  <MenuIcon />
                </StyledIconButtonMenu>
              }
              <div style={{ cursor: "pointer" }}>
                <img
                  src={Logo}
                  alt="logo"
                  width={60}
                  onClick={() => props.history.push("/homepage")}
                />
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <div className="headerAction">
                <div className="translate">
                  <StyledIconButtonTranslate
                    aria-label="translate"
                    size="small"
                    aria-controls={open_Trans ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open_Trans ? "true" : undefined}
                    onClick={handleClick_Trans}
                  >
                    <TranslateIcon fontSize="small" />
                  </StyledIconButtonTranslate>
                </div>
                <Menu
                  anchorEl={anchorEl_Trans}
                  open={open_Trans}
                  onClose={handleClose_Trans}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => changeLanguage("th")}>
                    <img
                      src="https://flagcdn.com/16x12/th.png"
                      alt="ภาษาไทย"
                      style={{ marginRight: "8px" }}
                    />
                    ภาษาไทย
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("en")}>
                    <img
                      src="https://flagcdn.com/16x12/gb.png"
                      alt="English"
                      style={{ marginRight: "8px" }}
                    />
                    English
                  </MenuItem>
                </Menu>
                <Divider
                  style={{ margin: "0 16px", height: 24 }}
                  orientation="vertical"
                />
                {isLoggedIn ? (
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      color="inherit"
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      size="large"
                    >
                      <Avatar
                        alt={
                          userProfile &&
                          `${userProfile.firstname_TH} ${userProfile.lastname_TH}`
                        }
                        src={(userProfile && userProfile.imageProfile) || ''}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    <ButtonBlue variant="outlined">Contact Us</ButtonBlue>
                    <ButtonBlue
                      variant="contained"
                      style={{ marginLeft: 8 }}
                      component={NavLink}
                      to="/login"
                    >
                      Sign in
                    </ButtonBlue>
                  </div>
                )}
              </div>
            </Container>
          </Toolbar>
        </StyledAppBar>
      </ElevationScroll>
      {renderMenu}
    </div>
  );
};

export default withRouter(Header);
