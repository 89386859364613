import React from "react";
import ListMenu from "../../../shared/listMenu";

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ForestRoundedIcon from '@mui/icons-material/ForestRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
import BadgeIcon from '@mui/icons-material/Badge';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';


import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import QuizIcon from '@mui/icons-material/Quiz';
import HistoryEduRoundedIcon from '@mui/icons-material/HistoryEduRounded';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';

const UserMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <DashboardRoundedIcon />,
      listItemText: "Dashboard",
      listLink: "/User/Dashboard",
      listKey: "user-dashboard",
    },
    // {
    //   listItemIcon: <DashboardRoundedIcon />,
    //   listItemText: "Dashboard",
    //   listLink: "",
    //   listKey: "Dashboard",
    //   collapse: [
    //     {
    //       listItemIcon: <DashboardIcon />,
    //       listItemText: "Dashboard Overview",
    //       listLink: "/User/Dashboard",
    //       listKey: "Dashboard",
    //     },
    //     {
    //       listItemIcon: <SchoolIcon />,
    //       listItemText: "Dashboard Skill",
    //       listLink: "/User/Dashboard/DashboardSkill",
    //       listKey: "DashboardSkill",
    //     },
    //     {
    //       listItemIcon: <VerifiedUserIcon />,
    //       listItemText: "Dashboard Competency",
    //       listLink: "/User/Dashboard/DashboardCompetency",
    //       listKey: "DashboardCompetency",
    //     },
    //     {
    //       listItemIcon: <TrendingUpIcon />,
    //       listItemText: "Learning Trend",
    //       listLink: "/User/Dashboard/LearningTrend",
    //       listKey: "LearningTrend",
    //     },
    //   ],
    // },
    {
      listItemIcon: <HomeRoundedIcon />,
      listItemText: "หน้าแรก",
      listLink: "/homepage",
      listKey: "home",
    },
    {
      listItemIcon: <MenuBookRoundedIcon />,
      listItemText: "หลักสูตร",
      listLink: "",
      listKey: "UserCourse",
      collapse: [
        {
          listItemIcon: <MenuBookRoundedIcon />,
          // listItemIcon: <TodayRoundedIcon />,
          listItemText: "หลักสูตรระหว่างเรียน",
          listLink: "/My-Course",
          listKey: "MyCourse",
        },
        {
          listItemIcon: <MenuBookRoundedIcon />,
          // listItemIcon: <TodayRoundedIcon />,
          listItemText: "หลักสูตรเร็วๆนี้",
          listLink: "/My-ComingSoon",
          listKey: "CourseComingSoon",
        },
        {
          listItemIcon: <AssignmentIcon />,
          listItemText: "หลักสูตรที่มอบหมาย",
          listLink: "/My-AssignCourse",
          listKey: "AssignCourse",
        },
      ],
    },
    {
      listItemIcon: <WorkspacePremiumRoundedIcon />,
      listItemText: "ใบประกาศของฉัน",
      listLink: "",
      listKey: "headCertificate",
      collapse: [
        {
          listItemIcon: <WorkspacePremiumRoundedIcon />,
          listItemText: "ใบประกาศ",
          listLink: "/MyCertificate",
          listKey: "MyCertificate",
        },
        {
          listItemIcon: <BadgeIcon />,
          listItemText: "ใบอนุญาต",
          listLink: "/MyLicense",
          listKey: "MyLicense",
        },
      ],
    },
    {
      // listItemIcon: <LocalLibraryRoundedIcon />,
      listItemIcon: <BusinessRoundedIcon />,
      listItemText: "ขออนุมัติอบรมภายนอก",
      listLink: "/RequestExternal",
      listKey: "RequestExternal",
    },
    {
      listItemIcon: <HistoryRoundedIcon />,
      listItemText: "ประวัติการเรียน",
      listLink: "/MyHistory",
      listKey: "MyHistory",
    },
    {
      listItemIcon: <QuizIcon />,
      listItemText: "Quiz",
      listLink: "",
      listKey: "Quiz",
      collapse: [
        {
          listItemIcon: <HistoryEduRoundedIcon />,
          // listItemText: "คำถามท้ายบทเรียน",
          listItemText: "Lesson Quiz",
          listLink: "/LessonQuiz",
          listKey: "MyQuiz",
        },
        {
          listItemIcon: <AlarmOnRoundedIcon />,
          listItemText: "Rush Quiz",
          listLink: "/Admin/RushQuiz",
          listKey: "AdminRushQuiz",
          disabled: true,
        },
      ],
    },
    {
      listItemIcon: <RememberMeIcon />,
      listItemText: "การ์ดความสามารถ",
      listLink: "/User/Competency/MyCompetency",
      listKey: "MyCompetency",
    },
    {
      listItemIcon: <SettingsSuggestRoundedIcon />,
      listItemText: "ทักษะ",
      listLink: "",
      listKey: "headSkill",
      collapse: [
        {
          listItemIcon: <SettingsSuggestRoundedIcon />,
          listItemText: "ทักษะทั้งหมด",
          listLink: "/AllSkill",
          listKey: "AllSkill",
        },
        {
          listItemIcon: <ManageAccountsRoundedIcon />,
          listItemText: "ทักษะของฉัน",
          listLink: "/MySkills",
          listKey: "MySkills",
        },
        {
          listItemIcon: <ManageAccountsRoundedIcon />,
          listItemText: "Skill Mapping",
          listLink: "/SkillMappingUser",
          listKey: "SkillMappingUser",
        },
      ],
    },
    {
      listItemIcon: <AssignmentTurnedInRoundedIcon />,
      listItemText: "On Job Training",
      listLink: "",
      listKey: "user-on-job-training",
      collapse: [
        {
          listItemIcon: <AssignmentTurnedInRoundedIcon />,
          listItemText: "OJT ของฉัน",
          listLink: "/My-OnJobTraining",
          listKey: "my-onjobtraining",
        },
        {
          listItemIcon: <AssignmentTurnedInRoundedIcon />,
          listItemText: "รายการสถานะ OJT",
          listLink: "/Status-OnJobTraining",
          listKey: "status-onjobtraining",
        },
      ],
    },
    {
      listItemIcon: <Groups2RoundedIcon />,
      listItemText: "แผนพัฒนาบุคคล",
      listLink: "",
      listKey: "headIDP",
      collapse: [
        {
          listItemIcon: <QuestionAnswerRoundedIcon />,
          listItemText: "Dialogue",
          listLink: "/IDP/User/Dialogue",
          listKey: "IDP_User_Dialogue",
        },
        {
          listItemIcon: <ManageAccountsRoundedIcon />,
          listItemText: "แผนพัฒนาของฉัน",
          listLink: "/IDP/User/MyIDP",
          listKey: "IDP_User_MyIDP",
        },
      ],
    },
    {
      listItemIcon: <PsychologyAltIcon />,
      listItemText: "แบบทดสอบศักยภาพ",
      listLink: "/User/Potential",
      listKey: "User-PotentialTest",
      disabled: true,
    },
    {
      listItemIcon: <EventNoteRoundedIcon />,
      listItemText: "สถานะรายการ",
      listLink: "/User/Status/StatusUser",
      listKey: "approvePerson",
    }
    // {
    //   listItemIcon: <PsychologyRoundedIcon />,
    //   listItemText: "ความสามารถ",
    //   listLink: "",
    //   listKey: "headCompetency",
    //   collapse: [
    //     // {
    //     //   listItemIcon: <ManageAccountsRoundedIcon />,
    //     //   listItemText: "ประเมินความสามารถตนเอง",
    //     //   listLink: "/User/Competency/RateCompetency",

    //     //   listKey: "RateCompetency",
    //     // },

    //     {
    //       listItemIcon: <RememberMeIcon />,
    //       listItemText: "การ์ดความสามารถ",
    //       listLink: "/User/Competency/MyCompetency",
    //       listKey: "CardsCompetency",
    //     },
    //   ],
    // },
  ];

  return <ListMenu key="user" dataListMenu={dataListMenu} menuRole={"User"} />;
};

export default UserMenu;
